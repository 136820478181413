









































































import { Vue, Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Account from '@/api/models/Account'
import Site from '@/api/models/Site'
import Connection from '@/api/models/Connection'
import { setFavicon, setTitle, getDocLink, getBrandKey } from '@/shared/components/helper'

@Component({})
export default class BaseLayout extends Vue {
    @Getter('isLoading')
    readonly loading!: boolean

    @Getter('connection')
    connection!: Connection

    @Getter('accounts')
    accountsList!: Array<Account>

    @Getter('sites')
    sites!: Array<Site>

    @Getter('getCurrentAccount')
    account!: Account

    @Action('loadConnection')
    loadConnection!: () => void

    @Action('loadSites')
    loadSites!: () => void

    @Action('selectAccount')
    selectAccount!: (account: Account) => Promise<void>

    async created (): Promise<void> {
        this.loadSites()
        try {
            await this.loadConnection()
            setFavicon(this.connection)
            setTitle(this.connection)
        } catch (e) {
            await this.$router.push('/')
        }

        if (!this.account && this.accountsList.length > 0) {
            await this.$store.dispatch('selectAccount', this.accountsList[0])
        }
    }

    private async changeAccount (accountId: number): Promise<void> {
        const selectedAccount = this.accountsList.find((account: Account) => account.id === accountId)
        if (selectedAccount) {
            await this.selectAccount(selectedAccount)
        }
    }

    get accounts (): Array<{account: number; site: string}> {
        const accounts: Array<{account: number; site: string}> = []

        const accountsList = this.accountsList
        if (accountsList) {
            accountsList.forEach((account: Account) => {
                const site = this.sites.find((site: Site) => site.code === account.site)
                if (site && account.id) {
                    accounts.push({ account: account.id, site: site.name })
                }
            })
        }

        return accounts
    }

    get isAccountList (): boolean {
        return this.$router.currentRoute.name === 'accounts'
    }

    get isInstaller (): boolean {
        return this.$router.currentRoute.name === 'account_install'
    }

    get logoUrl (): string {
        const brandKey = getBrandKey(this.connection)
        return `${process.env.VUE_APP_CDN}${brandKey}/logo/logo_icon.svg`
    }

    get crmUrl (): string {
        let crmUrl = this.connection.apiUrl
        if (crmUrl.slice(-1) !== '/') {
            crmUrl += '/'
        }
        return crmUrl
    }

    get integrationCrmUrl (): string {
        return `${this.crmUrl}admin/integration/list`
    }

    get docLink (): string {
        return getDocLink(this.connection)
    }
}
